import { Link } from "react-router-dom";
import { Stack } from "@mui/material";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";

export default function HelpButton({ label }) {
  return (
    <Stack direction="row" spacing={1} mt={"1vh"} mb={"1vh"}>
      {/* <QuestionMarkRoundedIcon
        color="black"
        fontSize="small"
        sx={{
          backgroundColor: "gray",
          borderRadius: "25px",
          verticalAlign: "top",
        }}
      /> */}
      <HelpOutlineIcon sx={{ color: "#333333", fontSize: "1.15em" }} />
      <Link className="NavLink" to="/helpPage">
        {label}
      </Link>
    </Stack>
  );
}
