import React from "react";
import IconButton from "@mui/material/IconButton";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { Link } from "react-router-dom";

export default function ProfileButton({ page }) {
  return (
    <IconButton
      aria-label="profile"
      size="large"
      component={Link}
      to={page}
      sx={{
        position: "fixed",
        top: "5.8vh",
        left: "5vw",
      }}
    >
      <AccountCircleIcon sx={{ width: "1.5em", height: "1.5em" }} />
    </IconButton>
  );
}
