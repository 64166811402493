import React, { useContext, useState, useRef } from "react";
import "./PasswordPage.css";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Box, Paper, Stack, TextField } from "@mui/material";
import {
  FormHeaderNoMargin,
  FormFooter,
  MyAlert,
  MyButton,
  BackButton,
} from "../../components";
import { LanguageContext } from "../../context/LanguageContext";
import { resetPassword, setAuthToken } from "../../context/UserContext";
import dictionary from "../../dictionary.json";
import { delay, validateEmail, validatePassword } from "../../utils/utils";

import background from "../../assets/images/background_phone.jpg";

export default function PasswordPage() {
  const navigate = useNavigate();
  const [lang] = useContext(LanguageContext);

  const [password, setPassword] = useState();
  const [confirmPw, setConfirmPw] = useState();
  const [isError, setError] = useState(false);
  const [validPw, setValidPw] = useState(false);
  const [open, setOpen] = useState(false);
  let username = "";
  let token = "";

  const [searchParams] = useSearchParams();

  try {
    username = searchParams.get("username");
    token = searchParams.get("key");
    setAuthToken(token);
  } catch (error) {
    console.log("Invalid Params");
  }

  let confirmPwRef = useRef(null);

  async function handleReset(username, password, confirmPassword) {
    const validPwd = validatePassword(password);
    setValidPw(validPwd);
    if (
      validateEmail(username) !== null &&
      validPwd &&
      password === confirmPassword
    ) {
      setError(false);
      setOpen(false);
      let ok = await resetPassword(username, password, confirmPassword, token);
      if (!ok) setError(true);
      setOpen(true);
      if (ok) {
        await delay(2000);
        navigate("/");
      }
    } else {
      setError(true);
      setOpen(true);
    }
  }

  function handleKeyDown(event, username, password, confirmPassword) {
    // If the key press is Enter
    if (event.key === "Enter") {
      switch (event.target.id) {
        case "password":
          confirmPwRef.current.focus();
          break;
        case "confirmpw":
          handleReset(username, password, confirmPassword);
          break;
        default:
          break;
      }
    }
  }

  return (
    <Box className="MainPage PasswordPage">
      <BackButton page="/" />
      <Paper
        className="PaperBody"
        elevation={3}
        sx={{
          backgroundColor: "rgba(255, 255, 255, 0.75)",
          borderRadius: "15px",
        }}
      >
        <FormHeaderNoMargin />
        <Stack
          className="PasswordForm"
          marginTop="3vh"
          marginBottom="2vh"
          spacing={{ xs: 2, sm: 2, md: 2 }}
          justifyContent="center"
          alignItems="center"
        >
          <TextField
            disabled
            value={username}
            id={"email"}
            label={dictionary.login_email[lang]}
            variant="outlined"
            size="small"
            fullWidth
          />
          <p>{dictionary.password_title[lang]}</p>
          <TextField
            variant="outlined"
            type="password"
            size="small"
            fullWidth
            error={isError}
            id={"password"}
            label={dictionary.forgot_pw[lang]}
            onChange={(e) => setPassword(e.target.value)}
            onKeyUp={(e) => handleKeyDown(e, username, password, confirmPw)}
          />
          <TextField
            variant="outlined"
            type="password"
            size="small"
            fullWidth
            error={isError}
            id={"confirmpw"}
            inputRef={confirmPwRef}
            label={dictionary.forgot_confirmtext[lang]}
            onChange={(e) => setConfirmPw(e.target.value)}
            onKeyUp={(e) => handleKeyDown(e, username, password, confirmPw)}
          />
          <br />
          <MyButton
            text={dictionary.forgot_register[lang]}
            event={(e) => handleReset(username, password, confirmPw)}
          />
        </Stack>
      </Paper>
      <br />
      <MyAlert
        open={open}
        setOpen={setOpen}
        isError={isError}
        validMsg={dictionary.password_validMsg[lang]}
        errorMsg={
          validPw
            ? dictionary.password_errorMsg[lang]
            : dictionary.password_incorrectPwd[lang]
        }
      />
      <FormFooter />
    </Box>
  );
}
