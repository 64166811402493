import react from "react";
import moment from "moment";

function useInstallPrompt() {
  const today = moment().format();
  const lastPrompt = moment(
    new Date(localStorage.getItem("installPrompt"))
  ).format();
  const days = moment(today).diff(lastPrompt, "days");

  const prompt = isNaN(days) || days > 7;
  return prompt;
}

export default useInstallPrompt;
