import React, { useState } from "react";
import { Route, Routes, Outlet, Navigate } from "react-router-dom";
import axios from "axios";
import "./App.css";
import LoginPage from "./pages/LoginPage/LoginPage";
import SelectEventPage from "./pages/SelectEventPage/SelectEventPage";
import WalletPage from "./pages/WalletPage/WalletPage";
import BadgePage from "./pages/BadgePage/BadgePage";
import HelpPage from "./pages/HelpPage/HelpPage";
import RegisterPage from "./pages/RegisterPage/RegisterPage";
import ForgotPage from "./pages/ForgotPage/ForgotPage";
import PasswordPage from "./pages/PasswordPage/PasswordPage";
import ProfilePage from "./pages/ProfilePage/ProfilePage";
import FirstConnectionPage from "./pages/FirstConnectionPage/FirstConnectionPage";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { WalletContext } from "./context/WalletContext";
import { useLocalStorage } from "./hooks/useLocalStorage";
import { UserContext, useAuth } from "./context/UserContext";
import { LanguageContext } from "./context/LanguageContext";
import { OnlineContext, OpenOnlineContext } from "./context/OnlineContext";
import { PWAInstall, PWAOfflineStatus } from "./components";
import config from "./config.json";

const theme = createTheme({
  typography: {
    fontFamily: [
      "Montserrat",
      "Roboto",
      "Helvetica Neue",
      "Arial",
      "sans-serif",
    ].join(","),
  },
  palette: {
    secondary: {
      main: "#FF5F01",
    },
    neutral: {
      main: "#CCCCCC4D",
      contrastText: "#454545",
    },
    default: {
      main: "#757575",
    },
  },
});

function PrivateRoutes() {
  const [{ islogged }] = useAuth();
  return islogged && islogged !== null ? <Outlet /> : <Navigate to="/login" />;
}

export default function App() {
  axios.defaults.baseURL = config.BE_URL;
  axios.defaults.withCredentials = true;
  axios.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response.status === 401) {
        localStorage.clear();
        console.log(window.location.href);
        window.location.href = "/";
      }
    }
  );
  const [user, setUser] = useLocalStorage("user", "");
  const [wallet, setWallet] = useLocalStorage("wallet", "");
  const [lang, setLang] = useLocalStorage("lang", "fr");
  const [isOnline, setOnlineStatus] = useState(true);
  const [openStatus, setOpenStatus] = useState(false);

  return (
    <ThemeProvider theme={theme}>
      <OnlineContext.Provider value={[isOnline, setOnlineStatus]}>
        <OpenOnlineContext.Provider value={[openStatus, setOpenStatus]}>
          <LanguageContext.Provider value={[lang, setLang]}>
            <UserContext.Provider value={[user, setUser]}>
              <WalletContext.Provider value={[wallet, setWallet]}>
                <PWAInstall />
                <PWAOfflineStatus lang={lang} />
                <Routes className="App">
                  <Route path="/login" element={<LoginPage />} />
                  <Route path="/forgotPassword" element={<ForgotPage />} />
                  <Route path="/password" element={<PasswordPage />} />
                  <Route path="/helpPage" element={<HelpPage />} />
                  <Route
                    path="/firstConnection"
                    element={<FirstConnectionPage />}
                  />
                  <Route element={<PrivateRoutes />}>
                    <Route path="/" element={<WalletPage />} />
                    <Route path="event" element={<SelectEventPage />} />
                    <Route path="badge" element={<BadgePage />} />
                    <Route path="register" element={<RegisterPage />} />
                    <Route path="profile" element={<ProfilePage />} />
                  </Route>
                </Routes>
              </WalletContext.Provider>
            </UserContext.Provider>
          </LanguageContext.Provider>
        </OpenOnlineContext.Provider>
      </OnlineContext.Provider>
    </ThemeProvider>
  );
}
