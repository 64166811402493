import React from "react";
import { forwardRef } from "react";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import OutlinedInput from "@mui/material/OutlinedInput";
import FormControl from "@mui/material/FormControl";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

const PasswordTextField = forwardRef(function PasswordTextField(props, ref) {
  const { label, onChange, onEnter, id, username, password, loginError } =
    props;

  const [showPassword, setShowPassword] = React.useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <FormControl
      variant="outlined"
      fullWidth
      size="small"
      sx={{ backgroundColor: "white", borderRadius: "4px" }}
    >
      <InputLabel htmlFor={id} error={loginError}>
        {label}
      </InputLabel>
      <OutlinedInput
        id={id}
        inputRef={ref}
        type={showPassword ? "text" : "password"}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
              edge="end"
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        }
        label={label}
        onChange={(e) => onChange(e.target.value)}
        onKeyUp={(e) => onEnter(e, username, password)}
        error={loginError}
        autoComplete="off"
      />
    </FormControl>
  );
});
export default PasswordTextField;
