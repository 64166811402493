import React from "react";
import { Button } from "@mui/material";

export default function MyButton({ text, event }) {
  return (
    <Button
      color="secondary"
      variant="contained"
      style={{
        maxWidth: "60vw",
        textTransform: "capitalize",
        borderRadius: 9,
      }}
      fullWidth
      onClick={event}
    >
      {text}
    </Button>
  );
}
