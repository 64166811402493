import { Fragment } from "react";
import PWAInstallEdge from "./PWAInstallEdge";
import PWAInstallFirefox from "./PWAInstallFirefox";
import PWAInstallIOS from "./PWAInstallIOS";
import PWAInstallOpera from "./PWAInstallOpera";
import PWAInstallSamsung from "./PWAInstallSamsung";

export default function PWAInstall() {
  // Chrome has an inbuilt intsall prompt
  return (
    <Fragment>
      <PWAInstallEdge />
      <PWAInstallFirefox />
      <PWAInstallIOS />
      <PWAInstallOpera />
      <PWAInstallSamsung />
    </Fragment>
  );
}
