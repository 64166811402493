import React, { useContext } from "react";
import "./WalletPage.css";
import { Box, Stack } from "@mui/material";

import {
  FormHeader,
  FormFooter,
  ProfileButton,
  AddButton,
  Wallet,
} from "../../components";
import { WalletContext } from "../../context/WalletContext";
import dictionary from "../../dictionary.json";
import { LanguageContext } from "../../context/LanguageContext";

export default function WalletPage() {
  const [wallet] = useContext(WalletContext);
  const [lang] = useContext(LanguageContext);
  const hasBadge = wallet.length > 0;
  return (
    <Box
      className="MainPage"
      sx={{
        alignItems: "center",
      }}
    >
      <ProfileButton page="/profile" />
      <FormHeader />
      <AddButton text={dictionary.wallet_addbadge[lang]} />
      {hasBadge ? (
        <Stack
          style={{
            marginTop: "4%",
            maxWidth: "60ch",
            width: "100%",
          }}
        >
          <p
            style={{
              textAlign: "center",
              fontWeight: "600",
              letterSpacing: "0.25em",
              margin: ".5em",
            }}
          >
            {dictionary.wallet_mybadge[lang]}
          </p>
          <Wallet cards={wallet} />
        </Stack>
      ) : (
        <Stack sx={{ height: "30%" }}>
          <br />
        </Stack>
      )}
      <FormFooter filled={true} />
    </Box>
  );
}
