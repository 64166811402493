import * as React from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

export default function MySelect({ setValue, options, text }) {
  return (
    <FormControl sx={{ width: "100%" }}>
      <InputLabel id="myselect-label" color="secondary">
        {text}
      </InputLabel>
      <Select
        labelId="myselect-label"
        label={text}
        onChange={(e) => setValue(e.target.value)}
        color="secondary"
        defaultValue=""
      >
        {options.map((item, idx) => {
          return (
            <MenuItem value={item} key={idx}>
              {item.label}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
}
