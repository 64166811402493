import React from "react";
import { Button } from "@mui/material";
import { Link } from "react-router-dom";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";

export default function AddButton({ text }) {
  return (
    <Button
      color="neutral"
      variant="contained"
      component={Link}
      to="/event"
      style={{
        textTransform: "none",
        borderRadius: 15,
        borderWidth: "thin",
        borderStyle: "solid",
        borderColor: "lightgray",
        fontSize: "2vmax",
        fontWeight: "600",
      }}
      sx={{
        marginTop: "6vh",
        maxWidth: "40ch",
        width: "80%",
      }}
      fullWidth
      startIcon={
        <AddCircleOutlineIcon
          color="secondary"
          style={{ verticalAlign: "middle", fontSize: "1.8em" }}
        />
      }
    >
      {text}
    </Button>
  );
}
