import React from "react";
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Link } from "react-router-dom";

export default function BackButton({ page }) {
  return (
    <IconButton
      aria-label="delete"
      component={Link}
      to={page}
      sx={{
        position: "fixed",
        top: "7vh",
        left: "5vw",
        zIndex: 10,
      }}
    >
      <ArrowBackIcon sx={{ width: "1.5em", height: "1.5em" }} />
    </IconButton>
  );
}
