import React from "react";
import LanguageSelect from "./LanguageSelect";
import logo from "../assets/images/rxlogo_orange.svg";

export default function FormHeaderNoMargin() {
  return (
    <header className="AppHeaderNoMargin">
      <LanguageSelect />
      <img
        alt="RxWallet logo"
        className="MainLogo"
        src={logo}
        style={{ height: "12vh" }}
      />
    </header>
  );
}
