import React, { useContext, useEffect, useState } from "react";
import "./FirstConnectionPage.css";
import { useSearchParams } from "react-router-dom";
import { Box, Paper, Stack, TextField } from "@mui/material";
import {
  BackButton,
  FormHeaderNoMargin,
  FormFooter,
  MyButton,
  MyAlert,
} from "../../components";
import dictionary from "../../dictionary.json";
import { getResetEmail } from "../../context/UserContext";
import { LanguageContext } from "../../context/LanguageContext";
import { delay, validateEmail } from "../../utils/utils";
import background from "../../assets/images/background_phone.jpg";

export default function FirstConnectionPage() {
  const [lang] = useContext(LanguageContext);
  const [open, setOpen] = useState(false);
  const [error, setError] = useState(false);
  const [timeOut, setTimeOut] = useState(false);
  const [searchParams] = useSearchParams();
  const [username, setUsername] = useState("");

  useEffect(() => {
    setUsername(searchParams.get("username") ?? "");
  }, [searchParams]);

  useEffect(() => {
    async function timer() {
      await delay(5000);
      setTimeOut(false);
    }
    if (timeOut) timer();
  }, [timeOut, setTimeOut]);

  async function handleSend(username) {
    if (timeOut) return false;
    else if (validateEmail(username) != null) {
      setError(false);

      let result = await getResetEmail(username);
      setError(!result);
      setOpen(true);
      // Set a timer to prevent spam (auto reset by UseEffect)
      setTimeOut(true);
    } else {
      setError(true);
      setOpen(true);
    }
  }

  function handleKeyDown(event) {
    if (event.key === "Enter") {
      handleSend(event.target.value);
    }
  }

  return (
    <Box className="MainPage FirstConnectionPage">
      <BackButton page="/" />
      <Paper
        className="PaperBody"
        elevation={3}
        style={{
          borderRadius: "15px",
          backgroundColor: "rgba(255, 255, 255, 0.75)",
        }}
      >
        <FormHeaderNoMargin />
        <Stack
          className="SendEmailForm"
          marginTop="3vh"
          marginBottom="2vh"
          spacing={{ xs: 1, sm: 2, md: 2 }}
          justifyContent="center"
          alignItems="center"
        >
          <b align="center" style={{ whiteSpace: "pre-line" }}>
            {dictionary.firstConnection_title[lang]}
          </b>
          <span align="center" style={{ whiteSpace: "pre-line" }}>
            {dictionary.firstConnection_ChangePassword[lang]}
          </span>
          <TextField
            id={"email"}
            label={dictionary.login_email[lang]}
            variant="outlined"
            size="small"
            fullWidth
            error={error}
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            onKeyUp={(e) => handleKeyDown(e)}
          />
          <br />
          <Stack width="80%" alignItems="center">
            <MyButton
              text={dictionary.firstConnection_sendEmail[lang]}
              event={(e) => handleSend(username)}
            />
          </Stack>
        </Stack>
      </Paper>
      <br />
      <MyAlert
        open={open}
        setOpen={setOpen}
        isError={error}
        validMsg={dictionary.forgot_valid[lang]}
        errorMsg={dictionary.forgot_InvalidAddress[lang]}
      />
      <FormFooter />
    </Box>
  );
}
