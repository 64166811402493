import React, { useEffect, useState, useContext } from "react";
import "./HelpPage.css";
import dictionary from "../../dictionary.json";
import { Box, Stack } from "@mui/material";
import { getAllEvents } from "../../context/EventContext";
import { LanguageContext } from "../../context/LanguageContext";
import { OnlineContext, OpenOnlineContext } from "../../context/OnlineContext";
import {
  FormFooter,
  BackButton,
  MySelect,
  MyAlert,
  FormHeaderNoMargin,
} from "../../components";

export default function HelpPage() {
  const [lang] = useContext(LanguageContext);
  const [isOnline] = useContext(OnlineContext);
  const [, setOpenStatus] = useContext(OpenOnlineContext);
  const [isError, setError] = useState(false);
  const [open, setOpen] = useState(false);
  const [events, setEventList] = useState([]);

  useEffect(() => {
    async function getData() {
      // let res = [
      //   {
      //     label: "POLLUTEC",
      //     value: "POL--23",
      //     url: "https://www.pollutec.com/fr-fr/participer.html",
      //     countryISO: "fr",
      //   },
      //   {
      //     label: "World Nuclear Exhibition",
      //     value: "WNE--23",
      //     url: "https://www.world-nuclear-exhibition.com/fr-fr.html#/",
      //     countryISO: "fr",
      //   },
      //   {
      //     label: "Sandwich & Snack Show",
      //     value: "SAND-24",
      //     url: "https://www.sandwichshows.com/",
      //     countryISO: "fr",
      //   },
      // ];
      let res = (await getAllEvents()) ?? [];
      setEventList(res);
    }

    if (isOnline === true) {
      getData();
    } else {
      setOpenStatus(true);
      // Set alert too
      setError(true);
      setOpen(true);
    }
  }, [isOnline, setOpenStatus]);

  useEffect(() => {
    setOpen(events !== null && events.length === 0 ? true : false);
  }, [events]);

  async function handleSelect(value) {
    window.location.href = value.helpDeskUrl;
    return true;
  }

  return (
    <Box
      className="MainPage"
      sx={{
        alignItems: "center",
      }}
    >
      <BackButton page="/" />
      <FormHeaderNoMargin />
      {/* Pading is needed to center the body element */}
      <Stack className="MainBody" spacing={4} pb={"15%"}>
        <h3 align="center" style={{ color: "#333333", whiteSpace: "pre-line" }}>
          {dictionary.help_title[lang]}
        </h3>
        <MySelect
          setValue={handleSelect}
          options={events}
          text={dictionary.event_event[lang]}
        />
        <MyAlert
          open={open}
          setOpen={setOpen}
          isError={isError}
          validMsg={
            "No events are openned at the moment." /**dictionary.event_validMsg[lang]**/
          }
          errorMsg={
            "You are offline, please reconnect to be redirected towards the correct helpdesk." /**dictionary.event_errorMsg[lang]**/
          }
        />
      </Stack>

      <FormFooter filled={true} />
    </Box>
  );
}
