import { useState, useEffect } from "react";

function getOrientation() {
  return window.screen.orientation.type;
}

function useScreenOrientation() {
  const [orientation, setOrientation] = useState(getOrientation());

  const updateOrientation = (event) => {
    setOrientation(getOrientation());
  };

  useEffect(() => {
    window.addEventListener("orientationchange", updateOrientation);
    return () => {
      window.removeEventListener("orientationchange", updateOrientation);
    };
  }, []);

  return ["portrait-primary", "portrait-secondary"].includes(orientation);
}

export default useScreenOrientation;
