import React, { useEffect } from "react";
import { Dialog, DialogContent, IconButton, Skeleton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

export default function AdPopup({ imageUrl }) {
  const [open, setOpen] = React.useState(true);
  const isValidUrl = imageUrl.trim() != "";
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth={"xs"}>
      <DialogContent sx={{ padding: "4%" }}>
        <IconButton
          size="small"
          style={{
            position: "absolute",
            top: "1%",
            right: "1%",
            backgroundColor: "#ff5f01e8",
            color: "white",
            zIndex: 2,
          }}
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
        {isValidUrl ? (
          <img
            alt="Sponsor Popup"
            src={imageUrl}
            style={{
              borderRadius: "5px",
              width: "100%",
            }}
            onClick={() => handleClose()}
          />
        ) : (
          <Skeleton variant="rounded" animation="wave" height="30vmax" />
        )}
      </DialogContent>
    </Dialog>
  );
}
