import React from "react";
import {
  Box,
  Dialog,
  DialogContent,
  Paper,
  Stack,
  Skeleton,
} from "@mui/material";

export default function Badge({ badge }) {
  let banner;
  try {
    banner = require(`../assets/images/banners/${badge.gbsCode}.jpg`);
  } catch (err) {
    banner = require(`../assets/images/banners/default.jpg`);
  }
  const eventLogo = require(`../assets/images/eventLogo/${badge.gbsCode}.jpg`);
  const type = require(`../assets/images/badgeTypes/${badge.visitorType}.png`);
  const [open, setOpen] = React.useState(false);
  const bdgData = badge.qrCode;

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Paper
      elevation={4}
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        //width: "100%",
        maxWidth: "30ch",
        // marginTop: "2vh",
        margin: "2vh 4% 0%",
        paddingTop: "3vh",
        borderRadius: "20px",
        zIndex: 1,
      }}
    >
      <Stack
        direction="row"
        sx={{
          width: "100%",
          display: "inline-flex",
          justifyContent: "space-between",
          marginTop: "2%",
          marginBottom: "2%",
        }}
      >
        <img
          alt="eventLogo"
          src={eventLogo}
          style={{ height: "6vh", maxWidth: "45%", paddingLeft: "4%" }}
        />
        <img
          alt="type"
          src={type}
          style={{ height: "6vh", maxWidth: "50%", paddingRight: "4%" }}
        />
      </Stack>
      <Stack
        direction="column"
        alignContent="center"
        justifyContent="space-around"
        alignItems="center"
        flexWrap="nowrap"
        width="100%"
        height="90%"
      >
        <img
          alt="Event banner"
          style={{
            maxHeight: "15vh",
            width: "100%",
            height: "30%",
          }}
          src={banner}
        />

        <Stack
          style={{
            direction: "column",
            alignContent: "inherit",
            alignItems: "inherit",
            width: "80%",
            height: "70%",
            marginTop: ".4em",
          }}
        >
          <p style={{ fontSize: "2.5vh", margin: 0 }}>
            {badge.firstName + " " + badge.lastName}
          </p>
          {badge.qrCode !== "" ? (
            <img
              alt="Badge QrCode"
              src={`data:image/jpeg;base64,${bdgData}`}
              style={{
                maxHeight: "30vh",
                maxWidth: "100%",
              }}
              onClick={() => handleClickOpen()}
            />
          ) : (
            <Skeleton variant="rectangular" width="10em" height="10em" />
          )}
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="responsive-dialog-title"
          >
            <DialogContent sx={{ padding: "0%" }}>
              <img
                alt="Badge QrCode"
                src={`data:image/jpeg;base64,${bdgData}`}
                style={{
                  width: "100%",
                  height: "100%",
                }}
                onClick={() => handleClose()}
              />
            </DialogContent>
          </Dialog>
          <span style={{ fontSize: "1.5vh", textAlign: "center" }}>
            {badge.badgeId}
          </span>
        </Stack>
        <p style={{ fontSize: "2vh", textAlign: "center", fontWeight: "600" }}>
          {badge.jobTitle} <br />
          {badge.country}
        </p>
      </Stack>
    </Paper>
  );
}
