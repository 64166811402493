import React from "react";
import { BadgeCard } from ".";
import { Box } from "@mui/material";

export default function Wallet({ cards }) {
  const isSingle = cards.length == 1 ? true : false;

  return (
    <Box
      style={{
        overflow: "scroll",
        height: "35vh",
        padding: 10,
        paddingTop: 50,
        paddingLeft: "10%",
        paddingRight: "10%",
        alignSelf: "center",
      }}
    >
      {cards.map((card, i) => {
        return (
          <BadgeCard idx={i} card={card} key={i} walletLenght={cards.length} />
        );
      })}
    </Box>
  );
}
