import React, { useContext } from "react";
import {
  BackButton,
  FormHeader,
  FormFooter,
  MyButton,
  FormHeaderNoMargin,
} from "../../components";
import BadgeIcon from "@mui/icons-material/Badge";
import { useLocation } from "react-router-dom";
import { Box, Divider, Stack, Typography } from "@mui/material";
import "./RegisterPage.css";
import dictionary from "../../dictionary.json";
import { LanguageContext } from "../../context/LanguageContext";
import useScreenOrientation from "../../hooks/useScreenOrientation";

export default function SelectEventPage() {
  const [lang] = useContext(LanguageContext);
  const location = useLocation();
  const { event } = location.state;

  function handleRegister(event) {
    window.open(event.url, "_blank");
  }

  return (
    <Box className="MainPage">
      <BackButton page="/event" />
      <FormHeaderNoMargin />
      <Stack direction="column" className="RegisterBody" pb="15%" spacing={3}>
        <Stack
          direction="column"
          alignItems="center"
          textAlign="center"
          spacing={1}
        >
          <BadgeIcon
            color="default"
            sx={{
              fontSize: "10vh",
              verticalAlign: "top",
            }}
          />
          <Typography>
            {dictionary.register_notregistered[lang]} {event.label}
          </Typography>
        </Stack>
        <MyButton
          text={dictionary.register_register[lang]}
          event={(e) => handleRegister(event)}
        />
      </Stack>
      <FormFooter filled={true} />
    </Box>
  );
}
