import { Link } from "react-router-dom";
import config from "../config.json";
import dictionary from "../dictionary.json";

export default function Policy({ lang }) {
  return (
    <a
      className="link"
      style={{ fontSize: ".8em", alignSelf: "initial" }}
      href={lang === "fr" ? config.POLICY_URL_FR : config.POLICY_URL}
      target="_blank"
      rel="noopener noreferrer"
    >
      {dictionary.policy[lang]}
    </a>
  );
}
