import { Box } from "@mui/material";
import React from "react";
import footerLogo from "../assets/images/RX_strapline_logo_RX_strap_horiz_primary.svg";

export default function FormFooter({ filled }) {
  return (
    <Box
      className="FormFooter"
      style={{
        display: "flex",
        position: "fixed",
        bottom: "0%",
        zIndex: 10,
        justifyContent: "center",
        width: "100%",
        height: "10%",
        backgroundColor: filled === true ? "#f0f0f0" : "",
        alignItems: "center",
      }}
    >
      <img
        alt="RxWallet Footer Logo"
        src={footerLogo}
        style={{ height: "3vh" }}
      />
    </Box>
  );
}
