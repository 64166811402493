import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import "./ProfilePage.css";
import { Box, IconButton, Stack, TextField, Typography } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import {
  BackButton,
  FormFooter,
  MyButton,
  Policy,
  FormHeaderNoMargin,
} from "../../components";
import { UserContext, Logout } from "../../context/UserContext";
import { WalletContext } from "../../context/WalletContext";
import { LanguageContext } from "../../context/LanguageContext";
import { OnlineContext, OpenOnlineContext } from "../../context/OnlineContext";
import useScreenOrientation from "../../hooks/useScreenOrientation";
import dictionary from "../../dictionary.json";

export default function ProfilePage() {
  const [lang] = useContext(LanguageContext);
  const [user, setUser] = useContext(UserContext);
  const [, setWallet] = useContext(WalletContext);
  const [isOnline] = useContext(OnlineContext);
  const [, setOpenStatus] = useContext(OpenOnlineContext);
  let isPortrait = useScreenOrientation();

  const navigate = useNavigate();
  return (
    <Box className="MainPage">
      <FormHeaderNoMargin />
      <BackButton page="/" />
      <Stack className="ProfileBody" alignItems="center" spacing={2}>
        <Stack
          direction={isPortrait ? "column" : "row"}
          alignItems="center"
          gap={1}
        >
          <AccountCircleIcon
            color="default"
            sx={{
              fontSize: isPortrait ? "10vh" : "auto",
              verticalAlign: "top",
            }}
          />
          <Typography>{dictionary.profile_title[lang]}</Typography>
        </Stack>

        <TextField
          className="ProfileTextField"
          size="small"
          label={dictionary.login_email[lang]}
          value={user.username}
          InputProps={{
            readOnly: true,
          }}
        />
        <TextField
          className="ProfileTextField"
          disabled
          size="small"
          id="passwordPlaceHolder"
          label={dictionary.login_pw[lang]}
          value="mypassword"
          type="password"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  //edge="end"
                  aria-label="Edit Password"
                  onClick={(e) => navigate("/forgotPassword")}
                >
                  <EditRoundedIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <MyButton
          text={dictionary.profile_disconnect[lang]}
          event={(e) => Logout(setUser, setWallet, isOnline, setOpenStatus)}
        />
        <Policy lang={lang} />
      </Stack>
      <FormFooter filled={false} />
    </Box>
  );
}
