import { useContext, createContext } from "react";
import axios from "axios";
import { browserName, osName } from "react-device-detect";

export const UserContext = createContext("");

export const useAuth = () => {
  //const user = useContext(UserContext);
  //console.log(user);
  return useContext(UserContext);
};

export function setAuthToken(token) {
  if (token) {
    axios.defaults.headers.common = { Authorization: `Bearer ${token}` };
  } else delete axios.defaults.headers.common["Authorization"];
}

async function getLogin(Username, Password) {
  const payload = {
    Username,
    Password,
    RememberLogin: true,
    Device: osName,
    Browser: browserName,
  };
  try {
    const response = await axios.post("Auth/login", payload);
    return response;
  } catch (error) {
    return null;
  }
}

export async function Login(setUser, setWallet, Username, Password) {
  let user = {
    username: "",
    islogged: false,
  };
  const response = await getLogin(Username, Password);
  if (response == null) return null;
  else if (response.status === 200) {
    user.username = response.data["username"];
    user.islogged = true;
    setUser(user);
    setWallet(response.data["wallet"]);
  }
  return response.status;
}

export async function Logout(setUser, setWallet, isOnline, setOpenStatus) {
  if (isOnline) {
    try {
      const response = await axios.get("Auth/logout");
      if (response.status === 200) {
        setUser("");
        localStorage.clear();
        return true;
      } else {
        alert("Could not log out.");
        return false;
      }
    } catch (error) {
      alert("Could not log out. Please try again later");
      return false;
    }
  }
  setOpenStatus(true);
  return false;
}

export async function getResetEmail(email) {
  const payload = { email };
  try {
    const response = await axios.post("Auth/resetEmail", payload);
    if (response.status === 200 || response.status === 204) return true;
    return false;
  } catch (error) {
    return false;
  }
}

export async function resetPassword(
  username,
  password,
  confirmPassword,
  token
) {
  const payload = { email: username, password, confirmPassword, token };
  try {
    var response = await axios.post("Auth/resetPassword", payload);
    if (
      response != null &&
      (response.status === 200 || response.status === 204)
    ) {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    //console.log(error);
    return false;
  }
}
