import { createContext } from "react";
import axios from "axios";

export const WalletContext = createContext([]);

export async function getBadge(gbsCode) {
  const payload = { gbsCode: gbsCode };
  try {
    const response = await axios.post("Badge", payload);
    if (response.status === 200) {
      const mybadge = response.data;
      return mybadge;
    }
    return null;
  } catch (error) {
    console.log("error:");
    console.log(error);
    return null;
  }
}
