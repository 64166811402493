import { Alert, IconButton, Collapse } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

export default function MyAlert({
  open,
  setOpen,
  isError,
  validMsg,
  errorMsg,
}) {
  return (
    <Collapse in={open}>
      <Alert
        severity={isError ? "error" : "success"}
        action={
          <IconButton
            aria-label="close"
            color="inherit"
            size="small"
            onClick={() => {
              setOpen(false);
            }}
          >
            <CloseIcon fontSize="inherit" />
          </IconButton>
        }
        sx={{ mb: 2, whiteSpace: "pre-line" }}
      >
        {isError ? errorMsg : validMsg}
      </Alert>
    </Collapse>
  );
}
