import * as React from "react";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { LanguageContext } from "../context/LanguageContext";

export default function LanguageSelect() {
  const [lang, setLang] = React.useContext(LanguageContext);

  const options = [
    { label: "Eng", value: "en" },
    { label: "Fr", value: "fr" },
  ];

  return (
    <FormControl
      size="small"
      sx={{
        position: "fixed",
        top: "8vh",
        right: "5vw",
      }}
    >
      <Select
        id="lang-select"
        variant="standard"
        value={lang}
        onChange={(e) => setLang(e.target.value)} // we can setLang directly here
        fullWidth
        sx={{
          fontSize: "default",
          fontWeight: "bold",
        }}
        color="secondary"
      >
        {options.map((item) => {
          return (
            <MenuItem
              value={item.value}
              key={item.value}
              id={"item-" + item.value}
            >
              {item.label}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
}
