import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Stack,
  Typography,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import React, { useContext } from "react";
import qr from "../assets/images/testData/QrCode.svg";
import { WalletContext, getBadge } from "../context/WalletContext";
import { OnlineContext } from "../context/OnlineContext";

export default function BadgeCard({ idx, card, walletLenght }) {
  var navigate = useNavigate();
  const [wallet, setWallet] = useContext(WalletContext);
  const [isOnline] = useContext(OnlineContext);
  const banner = require(`../assets/images/cardBanners/${card.gbsCode}.jpg`);
  const type = require(`../assets/images/badgeTypes/${card.visitorType}.png`);
  const isSingle = walletLenght == 1;
  const isLast = idx + 1 == walletLenght;
  const translatePos = 0;
  const translatePosX = 0; //-0.2 * idx;

  async function getData() {
    if (isOnline === true) {
      let newBadge = await getBadge(card.gbsCode);
      if (newBadge != null) {
        let updatedWallet = [...wallet];
        updatedWallet[idx] = newBadge;
        setWallet(updatedWallet);
        card.current = newBadge;
      }
    }
    navigate("/badge", { state: { badge: card, idx: idx } });
  }

  return (
    <Card
      key={idx}
      sx={{
        width: "100%",
        position: "sticky",
        height: isSingle ? "100%" : "100%",
        top: 10 * idx,
        zIndex: idx,
        // transform:
        //   idx > 1
        //     ? "translate(" + translatePosX + "%, " + translatePos + "%)"
        //     : "",
        boxShadow: "1px -4px 14px 2px rgba(26, 26, 26, 0.4);",
      }}
    >
      <CardActionArea onClick={() => getData()}>
        <Stack direction="row" alignItems="center">
          <CardMedia
            component="img"
            sx={{
              width: "100%",
              maxWidth: "100%",
              maxHeight: "60%",
              objectFit: "contain",
            }}
            image={banner}
            title={"card banner" + idx}
          />
          <img
            alt="type"
            src={type}
            style={{
              height: "auto",
              objectFit: "contain",
              position: "absolute",
              //top: "-10%", //"3.2vw",
              // bottom: "50%",
              // marginTop: "auto",
              // marginBottom: "auto",
              right: "10%",
            }}
          />
        </Stack>
        <CardContent sx={{ display: "grid", justifyItems: "center" }}>
          <Typography
            sx={{ fontSize: 14, fontWeight: "600" }}
            color="text.secondary"
          >
            {card.firstName + " " + card.lastName}
          </Typography>
          <img alt="Qr code placeholder" src={qr} />
        </CardContent>
      </CardActionArea>
    </Card>
  );
}
