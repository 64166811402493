import { React, useContext } from "react";
import { useLocation } from "react-router-dom";
import "./BadgePage.css";
import dictionary from "../../dictionary.json";
import { Box, Stack } from "@mui/material";
import MapIcon from "@mui/icons-material/Map";

import { FormFooter, BackButton, Badge, AdPopup } from "../../components";
import LanguageSelect from "../../components/LanguageSelect";
import { LanguageContext } from "../../context/LanguageContext";

export default function BadgePage() {
  const location = useLocation();
  let { badge, idx } = location.state;
  const [lang] = useContext(LanguageContext);

  return (
    <Box className="MainPage" style={{ justifyContent: "center" }}>
      <BackButton page="/" />
      <LanguageSelect />
      <Badge badge={badge} />
      {badge.planUrl === "" ? (
        ""
      ) : (
        <Stack direction="row" spacing={1} mt={"4vh"}>
          <MapIcon
            color="black"
            fontSize="small"
            sx={{
              verticalAlign: "top",
            }}
          />
          <a
            className="NavLink"
            href={badge.planUrl}
            target="_blank"
            rel="noreferrer"
          >
            {dictionary.badge_plan[lang]}
          </a>
        </Stack>
      )}
      <FormFooter filled={false} />
      <AdPopup imageUrl={badge.sponsorUrl} />
    </Box>
  );
}
