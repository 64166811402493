import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import "./SelectEventPage.css";
import dictionary from "../../dictionary.json";
import { Box, Stack, Fade } from "@mui/material";
import { getEvents, getCountries } from "../../context/EventContext";
import { WalletContext, getBadge } from "../../context/WalletContext";
import { LanguageContext } from "../../context/LanguageContext";
import { OnlineContext, OpenOnlineContext } from "../../context/OnlineContext";
import {
  FormHeader,
  FormFooter,
  BackButton,
  MySelect,
  MyAlert,
  FormHeaderNoMargin,
} from "../../components";

export default function SelectEventPage() {
  const navigate = useNavigate();
  const [lang] = useContext(LanguageContext);
  const [wallet, setWallet] = useContext(WalletContext);
  const [isOnline] = useContext(OnlineContext);
  const [, setOpenStatus] = useContext(OpenOnlineContext);
  const [isError, setError] = useState(false);
  const [open, setOpen] = useState(false);
  const [showEvent, setShowEvent] = React.useState(false);
  const [country, setCountry] = useState("");
  const [events, setEventList] = useState(null);
  const [countries, setCountries] = useState([]);

  useEffect(() => {
    async function getData() {
      let res = await getEvents(wallet);
      setEventList(res);
    }
    if (isOnline === true) getData();
    else setOpenStatus(true);
  }, [wallet, isOnline, setOpenStatus]);

  useEffect(() => {
    if (isOnline === false) {
      setError(true);
      setOpen(true);
    } else if (events !== null && events.length > 0) {
      // Set Events and Countries
      // setEventList(events);
      setCountries(getCountries(events, lang));
    }
    // Get Size to notify if no more events are available
    else if (events !== null && events.length === 0) setOpen(true);
  }, [events, lang, isOnline]);

  function handleCountry(item) {
    if (country !== item.value) {
      setCountry(item.value);
      setShowEvent(true);
    }
  }

  async function handleEvent(item) {
    let newBadge = await getBadge(item.value);
    if (newBadge == null) {
      let regEvent = item; //events.find((e) => e.value === value);
      navigate("/register", { state: { event: regEvent } });
    } else {
      setWallet((old) => [...old, newBadge]);
      navigate("/badge", { state: { badge: newBadge } });
    }
  }

  return (
    <Box
      className="MainPage"
      sx={{
        alignItems: "center",
      }}
    >
      <BackButton page="/" />
      <FormHeaderNoMargin />
      {/* Pading is needed to center the body element */}
      <Stack className="MainBody" spacing={2} pb={"15%"}>
        <div className="input-one" id={showEvent ? "inactive" : "active"}>
          <MySelect
            setValue={handleCountry}
            options={countries}
            text={dictionary.event_country[lang]}
          />
        </div>
        <Fade in={showEvent} style={{ transitionDelay: "200ms" }}>
          <div>
            <MySelect
              setValue={handleEvent}
              options={
                events !== null && isOnline
                  ? events.filter((e) => e.countryISO === country)
                  : []
              }
              text={dictionary.event_event[lang]}
            />
          </div>
        </Fade>
      </Stack>
      <MyAlert
        open={open}
        setOpen={setOpen}
        isError={isError}
        validMsg={dictionary.event_validMsg[lang]}
        errorMsg={dictionary.event_errorMsg[lang]}
      />
      <FormFooter filled={true} />
    </Box>
  );
}
