import React, { useEffect, useContext } from "react";
import { Alert, Snackbar } from "@mui/material";
import { OnlineContext, OpenOnlineContext } from "../context/OnlineContext";
import { LanguageContext } from "../context/LanguageContext";
import dictionary from "../dictionary.json";
import config from "../config.json";

function PWAOfflineStatus({ lang }) {
  const [isOnline, setOnlineStatus] = useContext(OnlineContext);
  const [openStatus, setOpenStatus] = useContext(OpenOnlineContext);
  // const [lang] = useContext(LanguageContext);

  function handleClose(event, reason) {
    if (reason === "clickaway") {
      return;
    }
    setOpenStatus(false);
  }

  useEffect(() => {
    function setFromEvent(event) {
      if (event.origin !== undefined) return;
      else if (event.type === "online") {
        setOnlineStatus(true);
        setOpenStatus(true);
      } else if (event.type === "offline") {
        setOnlineStatus(false);
        setOpenStatus(true);
      }
    }

    window.addEventListener("online", setFromEvent);
    window.addEventListener("offline", setFromEvent);

    return () => {
      window.removeEventListener("online", setFromEvent);
      window.removeEventListener("offline", setFromEvent);
    };
  });

  return !isOnline ? (
    <Snackbar
      open={openStatus}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      autoHideDuration={6000}
      onClose={handleClose}
    >
      <Alert onClose={handleClose} severity="error" sx={{ width: "90%" }}>
        {dictionary.pwa_offline[lang]}
      </Alert>
    </Snackbar>
  ) : (
    <Snackbar
      open={openStatus}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      autoHideDuration={6000}
      onClose={handleClose}
    >
      <Alert onClose={handleClose} severity="success" sx={{ width: "90%" }}>
        {dictionary.pwa_online[lang]}
      </Alert>
    </Snackbar>
  );
}

export default PWAOfflineStatus;
