import axios from "axios";
import dictionary from "../dictionary.json";

async function getEventList() {
  let events = [];
  let result = [];
  try {
    result = await axios.get("Event/GetEvents");
    events = result.data;
    return events;
  } catch (error) {
    return [];
  }
}

export async function getEvents(wallet) {
  let myEventList = [];
  let eventList = await getEventList();
  if (wallet && eventList.length) {
    myEventList = eventList.filter(
      ({ value: id1 }) => !wallet.some(({ gbsCode: id2 }) => id2 === id1)
    );
  } else myEventList = eventList;
  return myEventList;
}

export async function getAllEvents() {
  let eventList = await getEventList();
  return eventList;
}

export function getCountries(events, lang) {
  let countries = [];
  for (const e of events) {
    let hasCountry = countries.find((c) => c.value === e.countryISO);
    if (!hasCountry) {
      countries.push({
        label: dictionary.countries[e.countryISO][lang],
        value: e.countryISO,
      });
    }
  }
  return countries;
}
