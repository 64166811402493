import { useContext, useEffect, useState } from "react";
import dictionary from "../dictionary.json";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import InstallMobileRoundedIcon from "@mui/icons-material/InstallMobileRounded";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import useInstallPrompt from "../hooks/useInstallPrompt";
import { saveInstallPromptDate } from "../utils/utils";
import { LanguageContext } from "../context/LanguageContext";
import { isAndroid, isSamsungBrowser } from "react-device-detect";

export default function PWAInstallSamsung() {
  const [lang] = useContext(LanguageContext);
  const prompt = useInstallPrompt();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    // Detects if device is in standalone mode
    function isInStandaloneMode() {
      return "standalone" in window.navigator && window.navigator.standalone;
    }
    // Checks if should display install popup notification:
    if (!isInStandaloneMode() && isAndroid && isSamsungBrowser && prompt) {
      setOpen(true);
    }
  }, [prompt]);
  // Detects if device is on samsung

  const handleClose = () => {
    setOpen(false);
    saveInstallPromptDate();
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      maxWidth="md"
      open={open}
      PaperProps={{
        sx: {
          backgroundColor: "#EFEEF1",
          position: "absolute",
          bottom: "0%",
          width: "95%",
          borderRadius: "19px",
        },
      }}
    >
      <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
        <InstallMobileRoundedIcon
          color="action"
          sx={{
            backgroundColor: "white",
            borderRadius: 2,
            boxShadow: 3,
            margin: "5px 15px 5px 5px",
            padding: 1,
            verticalAlign: "middle",
          }}
        />
        <span>{dictionary.samsungInstallPrompt_title[lang]}</span>
      </DialogTitle>

      <DialogContent dividers>
        <Typography gutterBottom>
          {dictionary.samsungInstallPrompt_description[lang]}
        </Typography>
        <Typography component={"span"} gutterBottom>
          <List sx={{ listStyle: "decimal", pl: 2.5 }}>
            <ListItem sx={{ display: "list-item", pb: 0 }}>
              <ListItemText>
                {dictionary.samsungInstallPrompt_step1[lang] + " "}
                <ArrowDownwardIcon
                  color="primary"
                  fontSize="small"
                  sx={{
                    verticalAlign: "bottom",
                    border: "solid",
                    borderRadius: "9px",
                    marginLeft: "2px",
                  }}
                />
              </ListItemText>
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              <ListItemText>
                {dictionary.samsungInstallPrompt_step2[lang]}
                <Typography
                  display="inline"
                  sx={{
                    fontWeight: 600,
                    backgroundColor: "white",
                    borderRadius: "5px",
                    boxShadow: 1,
                    padding: 0.5,
                    marginLeft: 0.5,
                  }}
                >
                  {dictionary.samsungInstallPrompt_step2_add[lang]}
                </Typography>
              </ListItemText>
            </ListItem>
          </List>
        </Typography>
      </DialogContent>
      <IconButton
        aria-label="close"
        onClick={() => handleClose()}
        sx={{
          backgroundColor: "white",
          boxShadow: 2,
          position: "absolute",
          right: 9,
          bottom: 9,
          color: "action",
        }}
      >
        <CloseIcon />
      </IconButton>
    </Dialog>
  );
}
