import React, { useContext, useState } from "react";
import "./LoginPage.css";
import { useNavigate } from "react-router-dom";
import { Backdrop, Box, Paper, Stack, TextField } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import {
  ForgotPassword,
  FormFooter,
  MyButton,
  PasswordTextField,
  Policy,
  MyAlert,
  FormHeaderNoMargin,
  HelpButton,
} from "../../components";
import { UserContext, Login } from "../../context/UserContext";
import { LanguageContext } from "../../context/LanguageContext";
import { OnlineContext, OpenOnlineContext } from "../../context/OnlineContext";
import { validateEmail, validatePassword } from "../../utils/utils";
import dictionary from "../../dictionary.json";
import { WalletContext } from "../../context/WalletContext";

export default function LoginPage() {
  const [lang] = useContext(LanguageContext);

  const navigate = useNavigate();
  const [, setUser] = useContext(UserContext);
  const [, setWallet] = useContext(WalletContext);
  const [isOnline] = useContext(OnlineContext);
  const [, setOpenStatus] = useContext(OpenOnlineContext);
  const [username, setUsername] = useState();
  const [password, setPassword] = useState();
  const [loginError, setLoginError] = useState(false);
  const [responseStatus, setResponse] = useState(false);
  const [open, setOpen] = useState(false);
  const [openBackdrop, setOpenBackdrop] = useState(false);
  let one = React.useRef(null);
  let two = React.useRef(null);

  async function handleLogin(username, password) {
    const validPwd = validatePassword(password);
    let status = 0;
    if (isOnline) {
      if (validateEmail(username) != null && validPwd) {
        // - Reset Alerts and show backdrop while processing -
        setOpen(false);
        setOpenBackdrop(true);
        // --
        status = await Login(setUser, setWallet, username, password);
        if (status === 200) {
          setOpenBackdrop(false);
          navigate("/");
          return true;
        }
        setResponse(status);
        setOpenBackdrop(false);
      }
      setLoginError(true);
      setOpen(true);
      return false;
    } else {
      setOpenStatus(true);
    }
  }

  function handleKeyDown(event, username, password) {
    if (event.key === "Enter") {
      setLoginError(false);
      switch (event.target.id) {
        case "input-one":
          two.current.focus();
          break;
        case "input-two":
          handleLogin(username, password);
          break;
        default:
          break;
      }
    }
  }

  return (
    <Box className="MainPage LoginPage">
      <Paper
        className="PaperBody"
        elevation={3}
        style={{
          borderRadius: "15px",
          backgroundColor: "rgba(255, 255, 255, 0.75)",
        }}
      >
        <FormHeaderNoMargin />
        <form>
          <Stack
            className="LoginForm"
            marginTop="4vh"
            marginBottom={0}
            spacing={{ xs: 2, sm: 2, md: 2 }}
            justifyContent="center"
            alignItems="center"
          >
            <TextField
              id={"input-one"}
              inputRef={one}
              label={dictionary.login_email[lang]}
              variant="outlined"
              size="small"
              fullWidth
              onChange={(e) => setUsername(e.target.value)}
              error={loginError}
              onKeyUp={(e) => handleKeyDown(e, username, password)}
            />
            <PasswordTextField
              label={dictionary.login_pw[lang]}
              ref={two}
              id={"input-two"}
              onChange={setPassword}
              onEnter={handleKeyDown}
              username={username}
              password={password}
              loginError={loginError}
            />
            <Stack
              width="98%"
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Policy lang={lang} />
              <ForgotPassword label={dictionary.login_forgot[lang]} />
            </Stack>
            <MyButton
              text={dictionary.login_connection[lang]}
              event={(e) => handleLogin(username, password)}
            />
          </Stack>
        </form>
      </Paper>
      <HelpButton label={dictionary.login_help[lang]} />
      <MyAlert
        isError={loginError}
        open={open}
        setOpen={setOpen}
        errorMsg={
          responseStatus === 401
            ? dictionary.login_tooManyAttemps[lang]
            : dictionary.login_incorrectCredentials[lang]
        }
      />
      <FormFooter />
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openBackdrop}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Box>
  );
}
