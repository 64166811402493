import { Link } from "react-router-dom";

export default function ForgotPassword({ label }) {
  return (
    <Link
      className="link"
      style={{ fontSize: ".8em", alignSelf: "end" }}
      to="/forgotpassword"
    >
      {label}
    </Link>
  );
}
